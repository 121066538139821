export const API_URL = {
  GETUSER: "user/me",
  GETUSER2:'user/me/',
  SIGNIN: "auth/jwt/create/",
  GETUSERSCHOOL: "user/schools",
  SINGUPUSER: "user/signup/",
  FORGETPASSWORD: "user/forget-password/otp",
  OTPCONFIRM: "user/otp/confirm",
  NEWPASSWORD: "user/reset-password",
  SUBJECTLIST: "calculator/subject-list/",
  CALCULATEDATA: "calculator/calculate-coa-point/",
  GETGOALS: "education/quizzes/",
  GETPSYCHOMETRICTEST: "/psychometric/test/",
  GETUSERGOAL: "goals/",
  POSTUSERGOAL: "goals/",
  POSTPROFILE: "cv/create-cv/",
  GETPROFILE: "cv/get-cv/",
  GETEDUCATION:"cv/get-education/",
  UPDATEPROFILE: "cv/update-cv/",
  POSTEDUCATION: "cv/add-education/",
  POSTJUN: "cv/add-junior-cert/",
  GETEXPERIANCE:"cv/get-experience/",
  POSTEXPERIANCE:"cv/add-experience/",
  GETSKILLS:"cv/get-skill/",
  GETALLSKILLS:"cv/get-all-skill/",
  POSTSKILLS:"/cv/add-skill/",
  GETREFERANCE:'cv/get-reference/',
  POSTREFERNACE:'cv/add-reference/',
  GETINTREST:"cv/get-interest/",
  GETADITINALINFOEMATION:"cv/get-additional-info",
  POSTADITINALINFOEMATION:"cv/add-additional-info/",
  POSTINTREST:"cv/add-interest/",
  SAVEPDF:'cv/cv',
  ADDSLOTTABLE:'timetable/add-timeslot/',
  DASHBOARDTESTTYPES:'psychometric/testType/',
  GETCHOICES:'choices/selected/',
  REMOVECHOICES:'choices/selected/',
  GETMYGOALPDF:'goals/goalPdf/',
  DELETE:'/cv/delete-education',
  DELETE_EXPERIENCE:'/cv/delete-experience',
  DELETE_REFERENCE:'/cv/delete-reference',
  UPDATE_REFERENCE:'/cv/update-reference',
  DELETE_CAOPOINT:'/cao-calculator/delete-caopoint',
  DELETE_RESULT:'/cv/delete-junior-cert',
  DELETE_LEAVING:'/cv/delete-leaving-cert',
  SENDCV:'cv/sendcv/'
};
